import React, {forwardRef, useRef} from "react";
import {Row} from "react-bootstrap";
import Features01 from '../../img/features/features-01.svg'
import Features02 from '../../img/features/features-02.svg'
import Features03 from '../../img/features/feature-item-03.svg'
import {motion,} from "framer-motion";
import FeatureItem from "./FeatureItem";
import Background from "../Background/Background";

const Features = forwardRef(({trigger, pageSnapTrigger}, forwardedRef) => {
    const scope = useRef(null);
    const variants = {
        hidden: {
            opacity: 0,
        },
        show: {
            opacity: 1,
            transition: {
                type: 'beforeChildren',
                staggerChildren: 0.12,
                duration: .4,
                delay: .2
            },
        }
    }
    const featuresList = [
        {
            src: Features01,
            title: 'Ventas',
            subtitle: 'Optimiza el proceso de ventas al clasificar automáticamente a los clientes dentro del funnel, ubicándolos en el lugar que mejor corresponde a su interacción y necesidades.',
        },
        {
            src: Features02,
            title: 'Soporte',
            subtitle: 'Programado para responder las preguntas más comunes y proporcionar respuestas precisas y útiles. Esto alivia tu carga de contestación y contribuye a que brindes un servicio al cliente más ágil y eficiente',
        },
        {
            src: Features03,
            title: 'Cobrar',
            subtitle: 'Fortalece tu Estabilidad Financiera eliminamos las incertidumbres de impagos y cancelaciones inesperadas. Cada reserva se convierte en un avance seguro hacia el éxito financiero de tu negocio, optimizando la experiencia de usuario y la eficiencia de nuestras estrategias de conversión',
        },
    ];
    const snapVariants = {
        snap: {
            scrollSnapAlign: 'center',
            scrollSnapStop: 'always',
            transition: {
                duration: 0
            }
        },
        unsnap: {
            scrollSnapAlign: 'initial',
            scrollSnapStop: 'initial',
        }
    }
    return (
        <motion.section
            initial={'unsnap'}
            animate={(pageSnapTrigger) ? 'snap' : 'unsnap'}
            variants={snapVariants}
            className="features-section">
            <div  id={'features'} className={'position-absolute id-features-reference w-100'}></div>
            <Background color="white" trigger={trigger}/>
            <motion.div
                ref={scope}
                initial="hidden"
                animate={(trigger ? 'show' : 'hidden')}
                variants={variants}
                transition={{type: "spring", stiffness: 100}}
                className="features-container container"
            >
                <div ref={forwardedRef} className="features-scroll-reference"></div>
                <motion.div
                    className="row features-title-container px-4 mb-lg-3"
                    variants={variants}>
                    <h2 className="mb-3 mb-lg-2">¿Cómo puede ayudarte con tu negocio?</h2>
                    <p>Simplificá tu día y elevá la experiencia de tus clientes con nuestra solución
                        autoadministrable.</p>
                </motion.div>
                <Row className="p-3 features-item-container">
                    {
                        featuresList.map((feature, index) => {
                            return (
                                <FeatureItem
                                    src={feature.src}
                                    index={index}
                                    key={index}
                                    title={feature.title}
                                    subtitle={feature.subtitle}
                                />
                            )
                        })
                    }
                </Row>
            </motion.div>
        </motion.section>
    );
});

export default Features;