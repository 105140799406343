import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/styles.css'
import React from "react";
import HeaderSimplified from "./ThankYou/HeaderSimplified";
import ErrorPage from "./ErrorPage/ErrorPage";

function ErrorApp() {
    return (
        <>
            <HeaderSimplified/>
            <ErrorPage/>
        </>
    );
}

export default ErrorApp;
