import {useMediaQuery} from "react-responsive";

const Breakpoints = () => {
    const debug = false;
    const xsBreakpoint = useMediaQuery({query: `(max-width: 576px)`});
    const smBreakpoint = useMediaQuery({query: `(max-width: 768px)`});
    const mdBreakpoint = useMediaQuery({query: `(max-width: 992px)`});
    const lgBreakpoint = useMediaQuery({query: `(max-width: 1200px)`});
    const xlBreakpoint = useMediaQuery({query: `(max-width: 1400px)`});
    const xxlBreakpoint = useMediaQuery({query: `(min-width: 1401px)`});
    let isMobile = true;
    let isDesktop = false;
    let isXS = false;
    let isSM = false;
    let isMD = false;
    let isLG = false;
    let isXL = false;
    let isXXL = false;
    let current;
    if (xsBreakpoint) {
        if (debug) {
            console.log('xsBreakpoint');
        }
        current = 'xsBreakpoint';
        isXS = true;
    } else if (smBreakpoint) {
        if (debug) {
            console.log('smBreakpoint');
        }
        current = 'smBreakpoint';
        isSM = true;
    } else if (mdBreakpoint) {
        if (debug) {
            console.log('mdBreakpoint');
        }
        current = 'mdBreakpoint';
        isMD = true;
    } else if (lgBreakpoint) {
        if (debug) {
            console.log('lgBreakpoint');
        }
        current = 'lgBreakpoint';
        isLG = true;
        isMobile = false;
        isDesktop = true;
    } else if (xlBreakpoint) {
        if (debug) {
            console.log('xlBreakpoint');
        }
        current = 'xlBreakpoint';
        isXL = true;
        isMobile = false;
        isDesktop = true;
    } else if (xxlBreakpoint) {
        if (debug) {
            console.log('xxlBreakpoint');
        }
        current = 'xxlBreakpoint';
        isXXL = true;
        isMobile = false;
        isDesktop = true;
    }
    return {
        current,
        isMobile,
        isDesktop,
        isXS,
        isSM,
        isMD,
        isLG,
        isXL,
        isXXL
    };
}

export default Breakpoints;