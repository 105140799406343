import {useTransform, motion, circInOut} from "framer-motion";
import React, {forwardRef, useRef} from "react";
import Scroll from "../Utils/Scroll";
import Container from "react-bootstrap/Container";
import CellphoneAnimation from "./Cellphone/CellphoneAnimation";
import homeArcBackground from "../../img/home+details/home-arc-background.svg";
import Image from "react-bootstrap/Image";

const Home = forwardRef(({cpTrigger, setLastClicked}, forwardedRef) => {
            const sectionRef = useRef(null);
            const myScroll1 = Scroll({
                offsetStart: 'start start',
                offsetEnd: 'end start',
                containerRef: sectionRef,
                stiffness: 100,
            });
            const opacity = useTransform(myScroll1.scrollYProgress, [0, 0.22], [1, 0], {ease: circInOut});
            const filter = useTransform(myScroll1.scrollYProgress, v => `blur(${v}px)`);
            const scale = useTransform(opacity, [1, 0], [1, 1.1]);
            const mainButtonVariants = {
                initial: {
                    transform: 'scaleX(1)',
                    borderWidth: '1px',
                    background: '#FFFFFF00',
                    transition: {
                        duration: .2
                    }
                },
                hover: {
                    color: '#F56529',
                    background: '#FFF5EDFF',
                    transform: 'scaleX(1.03)',
                    borderBottomWidth: '4px',
                    transition: {
                        duration: .2
                    }
                }
            }

            function onClicked(buttonId) {
                setLastClicked(buttonId)
            }

            return (
                <section id={'home'} className="homeTitle" ref={sectionRef}>
                    <motion.div
                        className="container-fluid px-3 px-lg-0 pt-4 pt-lg-5 homeTitleContainer"
                        style={{opacity, filter, scale}}>
                        <h1 className="px-0 px-lg-2 text-center">Impulsa tu negocio con BOT2BME</h1>
                        <h3 className="mt-2 text-center col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            Llevá la experiencia de tus clientes al siguiente nivel. Simple, adaptable y diseñado para
                            simplificar operaciones <b>¡Probá nuestra demo y transformá tu negocio!</b>
                        </h3>
                        <div className="mt-4 d-flex justify-content-center text-center">
                            <motion.a
                                onClick={() => onClicked('ho-conoce')}
                                variant="tertiary"
                                initial={'initial'}
                                whileHover={'hover'}
                                type="button"
                                href={'#contact'}
                                variants={mainButtonVariants}
                                className="home-main-button px-5 py-1 px-lg-5 py-lg-2 btn btn-tertiary">
                                CONOCE BOT2BME
                            </motion.a>
                        </div>
                    </motion.div>
                    <motion.div className="home-empty-space" ref={forwardedRef}></motion.div>
                    <Container>
                        <Image
                            src={homeArcBackground}
                            className="home-arc-background"
                            fluid
                            alt="Background Image"
                        />
                    </Container>
                    <CellphoneAnimation trigger={cpTrigger}/>
                </section>
            );
        }
    )
;

export default Home;