import {motion} from "framer-motion";
import React, {useRef} from "react";

function FeatureItem({src, title, subtitle}) {
    const ref = useRef(null);
    const containerVariants = {
        show: {
            opacity: 1,
            skewY: 0,
            scaleX: 1,
            transition: {
                duration: .2,
            }
        },
        hidden: {
            opacity: 0,
            skewY: 12,
            scaleX: 0.5,
            transition: {
                duration: .2,
            }
        }
    }
    const imageVariants = {
        show: {
            borderWidth: '0px 0px 0px 0px',
            transition: {
                duration: .2,
            }
        },
        hidden: {
            borderWidth: '3px 22px 0px 0px',
            transition: {
                duration: .2,
            }
        }
    }
    return (
        <motion.div
            className="feature-item mb-5 col-lg-4 col-12"
            ref={ref}
            variants={containerVariants}>
            <motion.img
                alt={'feature image'}
                src={src}
                variants={imageVariants}
                className="img-fluid mb-3 position-relative"/>
            <h4>{title}</h4>
            <p>{subtitle}</p>
        </motion.div>
    )
}

export default FeatureItem;