import Image from "react-bootstrap/Image";
import React, {forwardRef, useContext} from "react";
import {motion} from "framer-motion";
import DetailsItem01 from '../../img/details/details-item-1.svg'
import DetailsItem02 from '../../img/details/details-item-2.svg'
import DetailsItem03 from '../../img/details/details-item-3.svg'
import DetailsItem04 from '../../img/details/details-item-4.svg'
import DetailsItem05 from '../../img/details/details-item-5.svg'

import Progress01 from '../../img/details/details-progress-1.png'
import Progress02 from '../../img/details/details-progress-2.png'
import Progress03 from '../../img/details/details-progress-3.png'
import Progress04 from '../../img/details/details-progress-4.png'
import Progress05 from '../../img/details/details-progress-5.png'
import DetailsPage from "./DetailsPage";
import BreakpointsContext from '../Utils/BreakpointsContext'
import Background from "../Background/Background";
import ArrowR from '../../img/flechita-der.svg'

const Details = forwardRef(({bgTrigger, pageSnapTrigger, setLastClicked}, forwardedRef) => {
    const breakpoints = useContext(BreakpointsContext);
    const pagesMap = [
        {
            src: DetailsItem01,
            inverseOrder: (breakpoints?.isDesktop) ? false : false,
            heading: <>Cobro Anticipado, Estabilidad Financiera</>,
            paragraph: <><b>Impulsá la estabilidad financiera de tu empresa con nuestro
                chatbot</b>: permite a tus clientes realizar reservas y pagos anticipados fácilmente,
                integrándose con
                calendarios como Google Calendar
                para una gestión eficiente.</>,
            buttonTarget: '',
            buttonLabel: <>DESCUBRI CÓMO FUNCIONA <Image src={ArrowR} className={'arrow'}/></>,
            progress: Progress01
        },
        {
            src: DetailsItem02,
            inverseOrder: breakpoints?.isDesktop,
            heading: <>Se adapta a tu industria y tus servicios</>,
            paragraph: <>Descubre nuestro chatbot versátil, diseñado para adaptarse
                a cualquier negocio y servicio.
                Experimenta una asistencia personalizada y eficiente,
                ideal para elevar tu empresa.</>,
            buttonTarget: '',
            buttonLabel: (breakpoints.isMobile) ? <>DESCUBRI CÓMO FUNCIONA <Image src={ArrowR} className={'arrow'}/></> : <>EXPLORÁ TODAS LAS
                POSIBILIDADES <Image src={ArrowR} className={'arrow'}/></>,
            progress: Progress02
        },
        {
            src: DetailsItem03,
            inverseOrder: (breakpoints?.isDesktop) ? false : false,
            heading: <>Asesora a tus clientes 24/7</>,
            paragraph: <>Con nuestro chatbot, disponible 24/7, tus clientes siempre estarán
                informados. Cárgalo con datos esenciales de tu empresa, como
                horarios y dirección, para proporcionar asesoramiento constante y
                personalizado.</>,
            buttonTarget: '',
            buttonLabel: <>DESCUBRI CÓMO FUNCIONA <Image src={ArrowR} className={'arrow'}/></>,
            progress: Progress03
        },
        {
            src: DetailsItem04,
            inverseOrder: breakpoints?.isDesktop,
            heading: <>Se adapta a tus canales preferidos</>,
            paragraph: <>Optimiza la comunicación de tu empresa con nuestro chatbot,
                diseñado para integrarse sin esfuerzo
                en tus canales preferidos, sin necesidad de cambiar tus métodos habituales.</>,
            buttonTarget: '',
            buttonLabel: (breakpoints.isMobile) ? <>DESCUBRI CÓMO FUNCIONA <Image src={ArrowR} className={'arrow'}/></> : <>EXPLORÁ TODAS LAS
                POSIBILIDADES <Image src={ArrowR} className={'arrow'}/></>,
            progress: Progress04
        },
        {
            src: DetailsItem05,
            inverseOrder: (breakpoints?.isDesktop) ? false : false,
            heading: <>Visualiza información valiosa de tus interacciones</>,
            paragraph: <>Descubre cómo nuestro chatbot revoluciona tu estrategia de marketing, ofreciéndote acceso
                directo a datos cruciales de tus interacciones, permitiéndote así tomar decisiones
                informadas y eficaces para potenciar tu negocio.</>,
            buttonTarget: '',
            buttonLabel: (breakpoints.isMobile) ? <>DESCUBRI CÓMO FUNCIONA <Image src={ArrowR} className={'arrow'}/></> : <>EXPLORÁ TODAS LAS
                POSIBILIDADES <Image src={ArrowR} className={'arrow'}/></>,
            progress: Progress05
        }
    ]
    const snapVariants = {
        snap: {},
        unsnap: {}
    }
    return (
        <motion.section
            id={'details'}
            ref={forwardedRef}
            className="details-section container-fluid m-0 p-0">
            <div className="details-empty-space"></div>
            <motion.div
                initial={'unsnap'}
                animate={(pageSnapTrigger) ? 'snap' : 'unsnap'}
                variants={snapVariants}
                className="snap-animation-wrapper">
                {pagesMap.map((page, index) =>
                    <DetailsPage
                        src={page.src}
                        alt={'image of a feature'}
                        index={index}
                        key={index}
                        heading={page.heading}
                        paragraph={page.paragraph}
                        buttonTarget={page.buttonTarget}
                        buttonLabel={page.buttonLabel}
                        inverseOrder={page.inverseOrder}
                        progress={page.progress}
                        isMobile={breakpoints.isMobile}
                        setLastClicked={setLastClicked}
                    />
                )}
            </motion.div>
            <Background color="blue" trigger={bgTrigger}/>
        </motion.section>)
});

export default Details;