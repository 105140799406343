import {useMotionValueEvent, useScroll, useSpring} from "framer-motion";

function Scroll(props) {
    const {scrollYProgress} = useScroll(
        {target: props.containerRef, offset: [props.offsetStart, props.offsetEnd]}
    );
    useMotionValueEvent(scrollYProgress, 'change', (value) => {
        if (props.debug) {
            console.log(value)
        }
    })
    const scrollYProgressSpring = useSpring(scrollYProgress, {
        stiffness: props.stiffness ? props.stiffness : 80,
        damping: props.damping ? props.damping : 10
    });
    return {scrollYProgress, scrollYProgressSpring}
}

export default Scroll;