import {motion, useInView} from "framer-motion";
import Image from "react-bootstrap/Image";
import React, {useRef} from "react";
import {Col} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import GlowBG from "../../img/details/glow.svg"
import GlowBGMobile from "../../img/details/glow-m.svg"

function DetailsPage({src, alt, heading, paragraph, isMobile, index, inverseOrder, buttonLabel, progress, setLastClicked}) {
    const fixedContainerRef = useRef();
    const pageIsInView = useInView(fixedContainerRef, {margin: `-50% 0% -50% 0%`})
    const translateLeftVariant = {
        pageVisible: {transform: 'translateX(0px)'},
        pageHidden: {transform: 'translateX(-9px)'},
    }
    const translateRightVariant = {
        pageVisible: {transform: 'translateX(0px)'},
        pageHidden: {transform: 'translateX(15px)'},
    }
    const itemContainerVariants = {
        snap: {
            scrollSnapAlign: 'center',
            scrollSnapStop: 'always',
            transition: {
                duration: 0
            }
        },
        unsnap: {
            scrollSnapAlign: 'initial',
            scrollSnapStop: 'initial',
        }
    }
    const fixedContainerVariants = {
        pageVisible: {
            opacity: 1,
            zIndex: 3,
            transition: {
                duration: .24,
                delay: .12,
                staggerChildren: .05
            }
        },
        pageHidden: {
            opacity: 0
        }
    }

    function onClicked(buttonId) {
        setLastClicked(buttonId)
    }

    return (
        <motion.div
            ref={fixedContainerRef}
            variants={itemContainerVariants}
            className="page-scroll-reference container">
            <motion.div
                initial={'pageHidden'}
                animate={(pageIsInView) ? 'pageVisible' : 'pageHidden'}
                viewport={{margin: '-50% 0% -50% 0%'}}
                variants={fixedContainerVariants}
                className="details-page-fixed-container container-fluid w-100 px-lg-5">
                <div className={'position-fixed w-100 h-100 d-flex justify-content-center'}>
                    <motion.img
                        src={(isMobile) ? GlowBGMobile : GlowBG}
                        className={`img-fluid ${(!inverseOrder) && 'glow-flipped'}`}
                    />
                </div>
                <Container className="px-lg-5">
                    <div className="row align-items-center">
                        <Col lg={1} xs={0}>
                            <Image src={progress} alt="Progress display" className="details-progress-img"/>
                        </Col>
                        <motion.div
                            variants={(isMobile) ? translateRightVariant : ((inverseOrder) ? translateLeftVariant : translateRightVariant)}
                            className={`col-11 col-lg-5 offset-1 offset-lg-0 p-lg-5 py-0 px-5 ${inverseOrder ? 'order-2' : 'd-flex details-page-fixed-container-col justify-content-center justify-content-lg-end'}`}>
                            <Image
                                src={src}
                                alt={alt}
                                fluid
                                className={`details-page-img ${inverseOrder && 'details-page-img-inverse-order'}`}
                            />
                        </motion.div>
                        <Col lg="6" xs="11"
                             className={`offset-1 offset-lg-0 details-page-txt ${(inverseOrder) ? 'details-page-txt-inverse-order' : ''}`}>
                            <Button href={'#contact'} as="a" className="details-page-button-invisible"
                                    onClick={() => onClicked(`'d-button-${index}`)}>
                                <motion.h4
                                    variants={(isMobile) ? translateRightVariant : ((inverseOrder) ? translateRightVariant : translateLeftVariant)}
                                    className="details-page-heading">{heading}</motion.h4>
                                <motion.p
                                    variants={(isMobile) ? translateRightVariant : ((inverseOrder) ? translateRightVariant : translateLeftVariant)}
                                    className="details-page-paragraph">{paragraph}</motion.p>
                                <motion.div
                                    variants={(isMobile) ? translateRightVariant : ((inverseOrder) ? translateRightVariant : translateLeftVariant)}
                                    role="button)"
                                    className="px-4 btn btn-primary main-button mt-1 mt-lg-3 details-page-button">{buttonLabel}</motion.div>
                            </Button>
                        </Col>
                    </div>
                </Container>
            </motion.div>

            <div className="details-page-empty-space"></div>
        </motion.div>
    )
}

export default DetailsPage;