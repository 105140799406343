import {motion, useTransform} from "framer-motion";
import Image from "react-bootstrap/Image";

function CellPhoneAnimationItem(props) {
    const transform = useTransform(props.scrollYProgress, (value) => {
        if (props.scale) {
            return `translate(${-1 * props.maxX * value + props.maxX}px,${-1 * props.maxY * value + props.maxY}px) scale(${(-1 * value * (props.scale - 1)) + props.scale})`
        } else {
            return `translate(${-1 * props.maxX * value + props.maxX}px,${-1 * props.maxY * value + props.maxY}px)`
        }
    })
    return (
        <motion.div className={`home-cellphone-animation-item home-animation-item-${props.index}`} style={{
            opacity: props.opacity,
            transform
        }}>
            <Image
                src={props.src}
                fluid
                alt={`Floating cellphone animation item ${props.index + 1}/${props.arraySize}`}
            />
        </motion.div>
    )
}

export default CellPhoneAnimationItem;