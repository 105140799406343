import {Col, Row, Image} from "react-bootstrap";
import Logo from '../../img/footer/logo-header-bg-white.svg'
import Button from "react-bootstrap/Button";
import {FaFacebook, FaInstagram, FaLinkedin, FaTwitter} from "react-icons/fa";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Nav from "react-bootstrap/Nav";
import {forwardRef} from "react";
import {motion} from "framer-motion";

const Footer = forwardRef(({setLastClicked}, forwardedRef) => {
    const footerVariants = {
        footerHidden: {
            scaleX: 0.05,
            transition: {
                duration: .4,
                delay: .2,
                type: 'spring'
            }
        },
        footerVisible: {
            scaleX: 1
        },
    }
    function onClicked(buttonId) {
        setLastClicked(buttonId)
    }
    return (
        <motion.footer
            initial={'footerHidden'}
            whileInView={'footerVisible'}
            variants={footerVariants}
            ref={forwardedRef}
            className={'mb-5 py-5 container z-2 position-relative'}>
            <Row className={'my-3 py-5'}>
                <div className={'my-5 pb-5 col-lg-12'}>
                    <Row className={'justify-content-center mb-3'}>
                        <Image
                            fluid
                            className={'footer-logo'}
                            src={Logo}/>
                    </Row>
                    <Row className={'mb-5 mb-lg-4'}>
                        <Nav as="ul" className={'footer-nav d-flex justify-content-center'}>
                            <Nav.Item as="li" className={'mx-2 my-1 my-lg-0'}>
                                <Nav.Link onClick={() => onClicked('f-details')} href="#details">Producto</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className={'mx-2 my-1 my-lg-0'}>
                                <Nav.Link onClick={() => onClicked('f-features')} href="#features">Casos de Uso</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className={'mx-2 my-1 my-lg-0'}>
                                <Nav.Link onClick={() => onClicked('f-about')} href="#about">Nosotros</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className={'mx-2 my-1 my-lg-0'}>
                                <Nav.Link onClick={() => onClicked('f-contact')} href="#contact">Contacto</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className={'mx-2 my-1 my-lg-0'}>
                                <Nav.Link onClick={() => onClicked('f-contact')} href="https://demo.crm.bot2b.me/">Ver la Demo</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Row>
                    <Row>
                        <Col className={'justify-content-center d-flex'}>
                            <ButtonGroup aria-label="Social icons" className="footer-social-icons">
                                <Button onClick={() => onClicked('f-instagram')} className="footer-social-icon rounded-circle mx-3"><FaInstagram/></Button>
                                <Button onClick={() => onClicked('f-facebook')} className="footer-social-icon rounded-circle mx-3"><FaFacebook/></Button>
                                <Button onClick={() => onClicked('f-twitter')} className="footer-social-icon rounded-circle mx-3"><FaTwitter/></Button>
                                <Button onClick={() => onClicked('f-linkedin')} className="footer-social-icon rounded-circle mx-3"><FaLinkedin/></Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </div>
            </Row>
        </motion.footer>)
})
export default Footer