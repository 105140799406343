import {motion} from "framer-motion";
import React from "react";

function Background({trigger, color}) {
    const bgVariants = {
        showBG: {
            opacity: 1,
            transition: {
                duration: .18
            }
        },
        hideBG: {
            opacity: 0,
            transition: {
                duration: .18
            }
        }
    }
    return (
        <motion.div
            initial={'hideBG'}
            animate={(trigger) ? 'showBG' : 'hideBG'}
            variants={bgVariants}
            className={`background-fixed background-fixed-${color}`}>
        </motion.div>
    )
}

export default Background;
