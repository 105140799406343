import {forwardRef} from "react";
import securityImage from '../../img/security/security-image.svg'
import {motion} from "framer-motion"
import Image from "react-bootstrap/Image";
import {Col, Row} from "react-bootstrap";

const Security = forwardRef(({trigger}, forwardedRef) => {
    const sectionVariant = {
        show: {
            translateX: '0%',
            transition: {
                duration: .2,
                delay: .1
            }
        },
        hidden: {
            translateX: '100%'
        }
    }
    return (
        <section id={'security'} ref={forwardedRef} className="security-section py-5 px-3">
            <div className="security-background"></div>
            <motion.div className="container px-2 px-lg-4" initial="hidden" animate={(trigger)?'show':'hidden'} transition={{duration: .66}} variants={sectionVariant}>
                <Row className="security-row-container px-2 py-3 px-lg-4 py-lg-5">
                    <Col lg={3} xs={12}>
                        <Image
                            className="security-img mb-3 mb-lg-0"
                            src={securityImage}
                            alt="Security section icons"
                            fluid
                        />
                    </Col>
                    <Col lg={9} xs={12} className="align-self-lg-center">
                        <h4>Seguridad</h4>
                        <p>En Bot2BMe, la seguridad de tus datos es nuestra máxima prioridad. Implementamos rigurosas
                            medidas de protección para garantizar un manejo seguro de la información de nuestros
                            clientes.<br/><br/><b>Puedes confiar en nosotros para brindarte un servicio de asistente
                                virtual
                                chatbot
                                seguro y fiable.</b></p>
                    </Col>
                </Row>
            </motion.div>
        </section>
    )
});

export default Security