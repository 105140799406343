import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/styles.css'
import Header from "./Header/Header";
import Home from "./Home/Home";
import Details from "./Details/Details";
import React, {useRef, useState} from "react";
import Breakpoints from "./Utils/Breakpoints";
import {useInView} from "framer-motion";
import Features from "./Features/Features";
import BreakpointsContext from './Utils/BreakpointsContext'
import Stories from "./Stories/Stories";
import Security from "./Security/Security";
import About from "./about/About";
import FormSection from "./Form/FormSection";
import Footer from "./Footer/Footer";


function App() {
    const [lastClicked, setLastClicked] = useState('');
    const breakpoints = Breakpoints();
    const ref01 = useRef(null);
    const ref02 = useRef(null);
    const ref03 = useRef(null);
    const ref04 = useRef(null);
    const ref05 = useRef(null);
    const ref06 = useRef(null);
    const ref07 = useRef(null);
    const ref08 = useRef(null);

    const homeIsInView = useInView(ref01);
    const detailsIsInView = useInView(ref02, {
        margin: `-50% 0% -50% 0%`
    });
    const featuresIsInView = useInView(ref03);
    const storiesIsInView = useInView(ref04);
    const securityIsInView = useInView(ref05);
    const aboutIsInView = useInView(ref06);
    const formIsInView = useInView(ref07);
    const footerIsInView = useInView(ref08);
    const elementsInView = {
        homeIsInView: homeIsInView,
        detailsIsInView: detailsIsInView,
        featuresIsInView: featuresIsInView,
        storiesIsInView: storiesIsInView,
        securityIsInView: securityIsInView,
        aboutIsInView: aboutIsInView,
        formIsInView: formIsInView,
        footerIsInView: footerIsInView,
    };
    return (
        <BreakpointsContext.Provider value={breakpoints}>
            <Header
                elementsInView={elementsInView}
                setLastClicked={setLastClicked}/>
            <Home
                ref={ref01}
                cpTrigger={!detailsIsInView}
                setLastClicked={setLastClicked}/>
            <Details
                ref={ref02}
                bgTrigger={!homeIsInView}
                pageSnapTrigger={detailsIsInView}
                setLastClicked={setLastClicked}/>
            <Features
                ref={ref03}
                pageSnapTrigger={detailsIsInView}
                trigger={featuresIsInView || storiesIsInView || securityIsInView || aboutIsInView || formIsInView || footerIsInView}/>
            <Stories
                ref={ref04}
                trigger={storiesIsInView || securityIsInView || aboutIsInView}
                setLastClicked={setLastClicked}/>
            <Security
                ref={ref05}
                trigger={securityIsInView}/>
            <About
                ref={ref06}
                pageSnapTrigger={detailsIsInView}
                trigger={aboutIsInView}/>
            <FormSection
                ref={ref07}
                pageSnapTrigger={detailsIsInView}
                lastClicked={lastClicked}/>
            <Footer
                ref={ref08}
                setLastClicked={setLastClicked}/>
        </BreakpointsContext.Provider>
    );
}

export default App;
