import Background from "../Background/Background";
import React from "react";
import {Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import {motion} from "framer-motion";
import ArrowR from '../../img/flechita-der.svg'

const ThankYou = () => {
    return (
        <section className={'thanks-section'}>
            <Background color="blue" trigger={true}/>
            <Container>
                <Row className={'d-flex align-content-center justify-content-center'}>
                    <h4>GRACIAS</h4>
                    <p className={'mb-3'}>Recibimos tu mensaje. Te estaremos respondiendo en breve.</p>
                    <motion.a
                        type="button"
                        href={'/'}
                        initial={{transform: 'scaleX(1)'}}
                        whileHover={{
                            transform: 'scaleX(1.03)',
                            transition: {duration: .12}
                        }}
                        className="btn btn-tertiary main-button button-navbar thanks-button ps-5 pe-5 ps-lg-3 pe-lg-3 pt-lg-2 pb-lg-2 ms-lg-2">
                        VOLVER AL SITIO <Image src={ArrowR} className={'arrow'}/>
                    </motion.a>
                </Row>
            </Container>
        </section>
    );
}
export default ThankYou