import {Image} from "react-bootstrap";
import item01 from '../../img/carrousel/item01.png'
import item02 from '../../img/carrousel/item02.png'
import item03 from '../../img/carrousel/item03.png'
import item04 from '../../img/carrousel/item04.png'
import item05 from '../../img/carrousel/item05.png'
import {useTime, useTransform, motion} from "framer-motion";
import glowImage from '../../img/carrousel/glow-left.svg'

const Carrousel = () => {
    const itemsMap = [
        {
            src: item01,
        },
        {
            src: item02,
        },
        {
            src: item03,
        },
        {
            src: item04,
        },
        {
            src: item05,
        }
    ]
    const time = useTime()
    const timeLapse = 22000;
    const translateX1 = useTransform(
        time,
        v => {
            let x = v % timeLapse
            return `${-200 * x * (1 / timeLapse) + 100}%`
        });
    const translateX2 = useTransform(
        time,
        v => {
            let x = v % timeLapse
            let result = -200 * x * (1 / timeLapse) + 100;
            return `${(result > 0) ? result - 100 : result + 100}%`
        });
    return (
        <div className={'py-3 my-4 px-0 container overflow-x-hidden position-relative'}>
            <motion.div
                style={{translateX: translateX1}}
                className={'container container-fluid carrousel-container position-absolute px-0'}>
                {itemsMap.map((item, index) =>
                    <motion.div
                        className="carrousel-item mx-2 mx-lg-0"
                        key={index}>
                        <Image
                            src={item.src}
                        />
                    </motion.div>
                )}
            </motion.div>
            <motion.div
                style={{translateX: translateX2}}
                className={'container container-fluid carrousel-container position-absolute px-0'}>
                {itemsMap.map((item, index) =>
                    <motion.div
                        className="carrousel-item"
                        key={index}>
                        <Image
                            src={item.src}
                        />
                    </motion.div>
                )}
            </motion.div>
            <div className="carrousel-empty-space p-0 m-0">
                <Image className="carrousel-glow carrousel-glow-inverse"
                       fluid
                       src={glowImage}/>
                <Image className="carrousel-glow"
                       fluid
                       src={glowImage}/>
            </div>
        </div>
    )
};
export default Carrousel;