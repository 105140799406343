import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/styles.css'
import React from "react";
import HeaderSimplified from "./ThankYou/HeaderSimplified";
import ThankYou from "./ThankYou/ThankYou";

function ThankYouApp() {
    return (
        <>
            <HeaderSimplified/>
            <ThankYou/>
        </>
    );
}

export default ThankYouApp;
