import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {FaInstagram, FaFacebook, FaTwitter, FaLinkedin} from "react-icons/fa";
import {motion, useAnimate} from "framer-motion";
import ArrowR from '../../img/flechita-der.svg'

import headerLogoBgWhite from '../../img/header/logo-header-bg-white.svg'
import headerLogoBgBlue from '../../img/header/logo-header-bg-blue.svg'
import headerLogoColor from '../../img/header/logo-header-color.svg'

function Header({elementsInView, setLastClicked}) {
    const {
        homeIsInView,
        featuresIsInView,
        storiesIsInView,
        securityIsInView,
        aboutIsInView,
        formIsInView,
        footerIsInView
    } = elementsInView;
    const [scope1, animate1] = useAnimate()
    const [scope2, animate2] = useAnimate()
    const [scope3, animate3] = useAnimate()
    const [scope4, animate4] = useAnimate()
    const bgWhite = homeIsInView || featuresIsInView || storiesIsInView || securityIsInView || aboutIsInView || formIsInView || footerIsInView;
    if (scope1.current && scope2.current && scope3.current) {
        animate1(scope1?.current, {opacity: +bgWhite}, {duration: .33})
        animate2(scope2?.current, {opacity: +!bgWhite}, {duration: .33})
        animate3('span', {color: (bgWhite) ? '#4A4A4A' : '#FFFFFF'}, {duration: .33});
        animate4(scope4?.current, {
            backgroundColor: (featuresIsInView || storiesIsInView || securityIsInView || aboutIsInView || formIsInView || footerIsInView) ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)',
            borderColor: (featuresIsInView || storiesIsInView || securityIsInView || aboutIsInView || formIsInView || footerIsInView) ? 'rgba(0,0,0,.1)' : 'rgba(0,0,0,0)',
        }, {duration: .33});
    }

    function onClicked(buttonId) {
        setLastClicked(buttonId)
    }

    return (
        <Navbar ref={scope4} expand="lg" className="header-navbar px-lg-5" fixed="top">
            <div className="ps-lg-0 pe-lg-0 container">
                <Navbar.Brand className="ms-0 ms-lg-auto pb-0 pt-0" href="#home">
                    <motion.img
                        ref={scope1}
                        src={headerLogoBgWhite}
                        width="196"
                        height="34"
                        className="d-inline-block align-top position-absolute"
                        alt="Header Logo for White Background"
                    />
                    <motion.img
                        ref={scope2}
                        src={headerLogoBgBlue}
                        style={{opacity: 0}}
                        width="196"
                        height="34"
                        className="d-inline-block align-top"
                        alt="Header Logo for Blue Background"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>

                <Navbar.Offcanvas
                    id={'basic-navbar-nav'}
                    aria-labelledby={'basic-navbar-nav-label'}
                    placement="end"
                    className="pb-lg-0 pb-3 ps-lg-0 pe-lg-0 ps-2 pe-2"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={'basic-navbar-nav-label'}>
                            <img
                                src={headerLogoColor}
                                width="193"
                                height="34"
                                className="d-inline-block align-top"
                                alt="Header Logo for Menu"
                            />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="d-flex flex-column mt-4 mt-lg-0">
                        <Nav ref={scope3}
                             className="col-12 ms-auto mt-0 mb-3 mb-lg-0 align-items-lg-center justify-content-lg-end">
                            <Nav.Link onClick={() => onClicked('h-details')} className="text-navbar-nav mb-lg-0 mb-2 me-lg-3" href="#details">
                                <span>
                                    <motion.div
                                        initial={{transform: 'scaleX(1)'}}
                                        whileHover={{transform: 'scaleX(1.06)', transition: {duration: .12}}}>
                                            Producto
                                            < /motion.div>
                                            </span>
                            </Nav.Link>
                            <Nav.Link onClick={() => onClicked('h-features')} className="text-navbar-nav mb-lg-0 mb-2 me-lg-4" href="#features">
                                            <span>
                                            <motion.div
                                                initial={{transform: 'scaleX(1)'}}
                                                whileHover={{transform: 'scaleX(1.06)', transition: {duration: .12}}}>
                                        Casos de uso
                                    </motion.div>
                                </span>
                            </Nav.Link>
                            <Nav.Link onClick={() => onClicked('h-contact')} className="text-navbar-nav mb-lg-0 mb-2 me-lg-4" href="#contact">
                                <span>
                                    <motion.div
                                        initial={{transform: 'scaleX(1)'}}
                                        whileHover={{
                                            transform: 'scaleX(1.06)',
                                            transition: {duration: .12}
                                        }}>
                                            Contacto
                                            < /motion.div>
                                            </span>
                            </Nav.Link>
                            <Nav.Link onClick={() => onClicked('h-about')} className="text-navbar-nav mb-lg-0 mb-2 me-lg-4" href="#about">
                                <span>
                                    <motion.div
                                        initial={{transform: 'scaleX(1)'}}
                                        whileHover={{transform: 'scaleX(1.06)', transition: {duration: .12}}}>
                                        Nosotros
                                    < /motion.div>
                                </span>
                            </Nav.Link>
                            <Form className="mt-lg-0 mt-2">
                                <motion.a
                                    type="button"
                                    href={'https://demo.crm.bot2b.me/'}
                                    initial={{transform: 'scaleX(1)'}}
                                    whileHover={{
                                        transform: 'scaleX(1.03)',
                                        transition: {duration: .12}
                                    }}
                                    onClick={() => onClicked('h-ver-la-demo')}
                                    className="btn btn-tertiary main-button button-navbar ps-5 pe-5 ps-lg-3 pe-lg-3 pt-lg-2 pb-lg-2 ms-lg-2">
                                    VER LA DEMO <Image src={ArrowR} className={'arrow'}/>
                                </motion.a>
                            </Form>
                        </Nav>

                        <ButtonGroup aria-label="Social icons"
                                     className="col-12 d-lg-none navbar-collapse-social-icons">
                            <Button onClick={() => onClicked('h-instagram')}
                                    className="navbar-social-icon rounded-circle me-2"><FaInstagram/></Button>
                            <Button onClick={() => onClicked('h-facebook')}
                                    className="navbar-social-icon rounded-circle me-2"><FaFacebook/></Button>
                            <Button onClick={() => onClicked('h-twitter')}
                                    className="navbar-social-icon rounded-circle me-2"><FaTwitter/></Button>
                            <Button onClick={() => onClicked('h-linkedin')}
                                    className="navbar-social-icon rounded-circle "><FaLinkedin/></Button>
                        </ButtonGroup>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </div>
        </Navbar>

    );
}

export default Header;