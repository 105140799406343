import {forwardRef, useContext} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import {Row, Image} from "react-bootstrap";
import FormImage from '../../img/form/image.svg'
import {motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import BreakpointsContext from '../Utils/BreakpointsContext'

const FormSection = forwardRef(({pageSnapTrigger, lastClicked}, forwardRef) => {
    const breakpoints = useContext(BreakpointsContext);
    const navigate = useNavigate();
    const snapVariants = {
        snap: {
            scrollSnapAlign: 'center',
            scrollSnapStop: 'always',
            transition: {
                duration: 0
            }
        },
        unsnap: {
            scrollSnapAlign: 'initial',
            scrollSnapStop: 'initial',
        }
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        let myForm = document.getElementById("website-contact-form_v3");
        let formData = new FormData(myForm);
        try {
            await fetch("/", {
                method: "POST",
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                body: new URLSearchParams(formData).toString(),
            })
            navigate('/thanks');
        } catch (error) {
            navigate('/error');
        }
    }
    return (
        <section ref={forwardRef} className={' mt-5 py-5 form-section px-3'}>
            <motion.div
                initial={'unsnap'}
                animate={(pageSnapTrigger) ? 'snap' : 'unsnap'}
                variants={snapVariants}
                id={'contact'}
                className={'position-absolute id-contact-reference'}></motion.div>
            <Container className={'form-section-container py-5'}>
                <Container fluid className={'form-image-container d-flex justify-content-center align-items-center'}>
                    <Image className={'position-absolute d-block'} src={FormImage}/>
                </Container>
                <h4 className={'text-center mt-4'}>¿Querés que Bot2BMe te ayude con las necesidades de tu negocio?</h4>
                <p className={'text-center'}>Pongámonos en contacto y armemos una opción a medida</p>
                <Row className={'d-flex justify-content-center'}>
                    <div className={`py-lg-2 py-2 col-12 col-lg-6`}>
                        <Form id={'website-contact-form_v3'} name="website-contact-form_v3" method="POST" onSubmit={submitHandler}>
                            <input type="hidden" name="form-name" value="website-contact-form_v3"/>
                            <Form.Group className="mb-3" controlId="form-contact-name">
                                <Form.Label className={'ms-2 mb-1'}>Nombre y Apellido / Nombre empresa</Form.Label>
                                <Form.Control required placeholder="Ingresá tu nombre o razón social"
                                              name={'form-contact-name'}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="form-email">
                                <Form.Label className={'ms-2 mb-1'}>Tu correo electrónico</Form.Label>
                                <Form.Control required type="email" placeholder="mail@ejemplo.com" name={'form-email'}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="form-message">
                                <Form.Label className={'ms-2 mb-1'}>Dejanos tu mensaje</Form.Label>
                                <Form.Control required placeholder="Escribí tu mensaje" name={'form-message'}/>
                            </Form.Group>
                            <input type="hidden" id="form-last-clicked" name="form-last-clicked" value={lastClicked}/>
                            <input type="hidden" id="bot-field" name="bot-field"/>
                            <input type="hidden" id="form-client-screen-size"
                                   name="form-client-device-screen-size" value={breakpoints.current}/>
                            <Button
                                variant="tertiary"
                                className={'main-button mt-3 w-100'}
                                type="submit">
                                ENVIAR
                            </Button>
                        </Form>
                    </div>
                </Row>
            </Container>
        </section>
    )
});
export default FormSection