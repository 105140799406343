import {forwardRef, useState} from "react";
import Container from "react-bootstrap/Container";
import {Card, Col, Row, Image} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {motion, useCycle} from "framer-motion"
import Item01 from '../../img/stories/stories-item-01.png'
import Item02 from '../../img/stories/stories-item-02.png'
import Item03 from '../../img/stories/stories-item-03.png'
import Item04 from '../../img/stories/stories-item-04.png'
import ArrowL from '../../img/flechita-izq.svg'
import ArrowR from '../../img/flechita-der.svg'

import Progress01 from '../../img/stories/progress-1.svg'
import Progress02 from '../../img/stories/progress-2.svg'
import Progress03 from '../../img/stories/progress-3.svg'
import Progress04 from '../../img/stories/progress-4.svg'

const Stories = forwardRef(({trigger, setLastClicked}, forwardedRef) => {
    const [index, setIndex] = useState(0);
    const [variant1, cycleVariant1] = useCycle('center', 'right', 'hidden', 'left');
    const [variant2, cycleVariant2] = useCycle('right', 'hidden', 'left', 'center');
    const [variant3, cycleVariant3] = useCycle('hidden', 'left', 'center', 'right',);
    const [variant4, cycleVariant4] = useCycle('left', 'center', 'right', 'hidden');
    const indexMap1 = {
        center: 0,
        right: 1,
        hidden: 2,
        left: 3
    }
    const indexMap2 = {
        center: 3,
        right: 0,
        hidden: 1,
        left: 2
    }
    const indexMap3 = {
        center: 2,
        right: 3,
        hidden: 0,
        left: 1
    }
    const indexMap4 = {
        center: 1,
        right: 2,
        hidden: 3,
        left: 0
    }
    const stories = [
        {
            heading: 'LUCIANO SAEZ - AGENTE INMOBILIARIO',
            quote: 'Mejoró el manejo de las rentas de mis propiedades, automatizando respuestas y reduciendo tiempos. Esencial para el éxito inmobiliario.”',
            initial: 'center',
            variant: variant1,
            src: Item01
        },
        {
            heading: 'MICAELA ORTEGA - PEDIATRA',
            quote: 'Simplificó la gestión de las consultas: eficiencia sin precedentes, menos carga administrativa, más enfoque en pacientes.”',
            initial: 'right',
            variant: variant2,
            src: Item02
        },
        {
            heading: 'CAMILA HERRERA - TATUADORA',
            quote: 'El chatbot revolucionó nuestro estudio de tatuajes: simplificó reservas, organizó agendas y elevó la experiencia del cliente.”',
            initial: 'hidden',
            variant: variant3,
            src: Item03
        },
        {
            heading: 'SAMUEL ROMANO - DOCENTE',
            quote: 'Bot2BMe revolucionó nuestra comunicación con ingresantes, brindando información clave de manera instantánea y eficiente. ¡Imprescindible!”',
            initial: 'left',
            variant: variant4,
            src: Item04
        }
    ];
    const itemVariant = {
        left: {
            translateX: '-50%',
            scale: 0.6,
            opacity: 0.1,
            zIndex: 1,
            transition: {
                duration: .5,
                ease: 'easeInOut'
            },
        },
        center: {
            opacity: 1,
            translateX: '0%',
            scale: 1,
            zIndex: 2,
            transition: {
                duration: .5,
                ease: 'easeInOut'
            },
        },
        right: {
            translateX: '50%',
            scale: 0.6,
            opacity: 0.1,
            zIndex: 1,
            transition: {
                duration: .5,
                ease: 'easeInOut'
            },
        },
        hidden: {
            opacity: 0,
            translateX: '0%',
            scale: 0,
            zIndex: -1,
            transition: {
                duration: .5,
                ease: 'easeInOut'
            },
        }
    }
    const sectionVariants = {
        show: {
            opacity: 1,
            transition: {
                duration: .6,
                delay: .3
            }
        },
        hidden: {
            opacity: 0,
        }
    }

    function previous() {
        cycleVariant1();
        cycleVariant2();
        cycleVariant3();
        cycleVariant4();
        setIndex((index === 0) ? 3 : index - 1);
    }

    function next() {
        let newIndex1 = (indexMap1[variant1] === 0) ? 3 : (indexMap1[variant1] - 1);
        let newIndex2 = (indexMap2[variant2] === 0) ? 3 : (indexMap2[variant2] - 1);
        let newIndex3 = (indexMap3[variant3] === 0) ? 3 : (indexMap3[variant3] - 1);
        let newIndex4 = (indexMap4[variant4] === 0) ? 3 : (indexMap4[variant4] - 1);
        cycleVariant1(newIndex1);
        cycleVariant2(newIndex2);
        cycleVariant3(newIndex3);
        cycleVariant4(newIndex4);
        setIndex((index === 3) ? 0 : index + 1);
    }

    function onClicked(buttonId) {
        setLastClicked(buttonId)
    }

    return (
        <motion.section id={'stories'} className="stories-section py-5" ref={forwardedRef} initial="hidden"
                        transition={{duration: 1.5}}
                        animate={(trigger) ? 'show' : 'hidden'} variants={sectionVariants}>
            <div className="stories-background"></div>
            <div>
                <Container className="mt-5">
                    {
                        stories.map((story, index, array) => {
                            return (
                                <motion.div className="container mt-5 stories-container-item" key={index}
                                            initial={story.initial} variants={itemVariant} animate={story.variant}>
                                    <div className="px-3 row stories-row-item">
                                        <Col lg={2} xs={0}></Col>
                                        <Col lg={8} xs={12}>
                                            <Card className="stories-card-container">
                                                <Card.Body>
                                                    <Container className="px-2 px-lg-5 py-lg-3">
                                                        <Row className="stories-row-container">
                                                            <Col xs={12} lg={4}
                                                                 className="stories-image-col-container">
                                                                <Image
                                                                    src={story.src}
                                                                    alt="Figura humana"
                                                                    fluid
                                                                />
                                                            </Col>
                                                            <Col className="stories-col-container align-self-lg-center"
                                                                 xs={12} lg={8}>
                                                                <h4>{story.heading}</h4>
                                                                <blockquote
                                                                    className="mt-1 mt-lg-0">{story.quote}</blockquote>
                                                                <Button
                                                                    onClick={() => onClicked(`s-button-${index}`)}
                                                                    href={'#contact'}
                                                                    className="stories-cta-button mt-1 mb-1 mb-lg-0 mt-lg-3">
                                                                    Soy este cliente
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={2} xs={0}></Col>
                                    </div>
                                </motion.div>
                            )
                        })
                    }
                </Container>
                <div className="stories-empty-space"></div>
                <div className="stories-controls px-0 px-lg-5">
                    <Container className="d-flex justify-content-between px-0 px-lg-5">
                        <Button className="p-1 mx-2 mx-lg-0" onClick={previous}>
                            <Image className="m-2 stories-controls-arrow" src={ArrowL} fluid/>
                        </Button>
                        <Button className="p-1 mx-2 mx-lg-0" onClick={next}>
                            <Image className="m-2 stories-controls-arrow" src={ArrowR} fluid/>
                        </Button>
                    </Container>
                </div>
                <div className="stories-progress m-2">
                    <motion.img
                        src={Progress01}
                        animate={(index === 0) ? 'show' : 'hidden'}
                        variants={sectionVariants}
                        className="stories-progress-image img-fluid"
                    />
                    <motion.img
                        src={Progress02}
                        animate={(index === 1) ? 'show' : 'hidden'}
                        variants={sectionVariants}
                        className="stories-progress-image img-fluid"
                    />
                    <motion.img
                        src={Progress03}
                        animate={(index === 2) ? 'show' : 'hidden'}
                        variants={sectionVariants}
                        className="stories-progress-image img-fluid"
                    />
                    <motion.img
                        src={Progress04}
                        animate={(index === 3) ? 'show' : 'hidden'}
                        variants={sectionVariants}
                        className="stories-progress-image img-fluid"
                    />
                </div>
            </div>
        </motion.section>
    )
});

export default Stories;