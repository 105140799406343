import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import mainCellphone from "../../../img/home+details/home-main-cellphone.svg";
import animationItem01 from "../../../img/home+details/animations/01.svg";
import animationItem02 from "../../../img/home+details/animations/02.svg";
import animationItem03 from "../../../img/home+details/animations/03.svg";
import animationItem04 from "../../../img/home+details/animations/04.svg";
import animationItem05 from "../../../img/home+details/animations/05.svg";
import animationItem06 from "../../../img/home+details/animations/06.svg";
import animationItem07 from "../../../img/home+details/animations/07.svg";
import animationItem08 from "../../../img/home+details/animations/08.svg";
import animationItem09 from "../../../img/home+details/animations/09.svg";
import animationItem10 from "../../../img/home+details/animations/10.svg";
import animationItem11 from "../../../img/home+details/animations/11.svg";
import {motion} from "framer-motion";
import React, {useRef} from "react";
import CellPhoneAnimationItem from './CellPhoneAnimationItem'
import Scroll from '../../Utils/Scroll'
import ScrollOffsets from "../../Utils/ScrollOffsets";
import {useContext} from 'react';
import BreakpointsContext from '../../Utils/BreakpointsContext'

const CellphoneAnimation = ({trigger}) => {
    const cpRef = useRef(null);
    const breakpoints = useContext(BreakpointsContext);
    const scrollOffsets1 = ScrollOffsets({breakpoint: breakpoints.current})
    scrollOffsets1.set('xsBreakpoint', {offsetStart: '300px end', offsetEnd: '520px end'});
    scrollOffsets1.set('smBreakpoint', {offsetStart: '380px end', offsetEnd: '560px end'});
    scrollOffsets1.set('mdBreakpoint', {offsetStart: '420px end', offsetEnd: '610px end'});
    scrollOffsets1.set('lgBreakpoint', {offsetStart: '480px end', offsetEnd: '640px end'});
    scrollOffsets1.set('xlBreakpoint', {offsetStart: '520px end', offsetEnd: '720px end'});
    scrollOffsets1.set('xxlBreakpoint', {offsetStart: '580px end', offsetEnd: '740px end'});
    let marginMultiplier = 1;
    if ((breakpoints?.isDesktop)) {
        marginMultiplier = 1;
    }
    const containerRef = useRef(null);
    const myScroll1 = Scroll({
        offsetStart: scrollOffsets1.getCurrent()?.offsetStart,
        offsetEnd: scrollOffsets1.getCurrent()?.offsetEnd,
        containerRef,
    });
    const images = [
        {
            src: animationItem11,
            opacity: myScroll1.scrollYProgress
        },
        {
            src: animationItem01,
            maxX: 312 * marginMultiplier,
            maxY: 79 * marginMultiplier,
            scale: 1.33
        },
        {
            src: animationItem02,
            maxX: -353 * marginMultiplier,
            maxY: 48 * marginMultiplier,
            scale: 1.1
        },
        {
            src: animationItem03,
            maxX: -252 * marginMultiplier,
            maxY: -49 * marginMultiplier,
        },
        {
            src: animationItem04,
            maxX: -65 * marginMultiplier,
            maxY: -8 * marginMultiplier,
        },
        {
            src: animationItem05,
            maxX: -126 * marginMultiplier,
            maxY: -9 * marginMultiplier,
            scale: 1.1
        },
        {
            src: animationItem06,
            maxX: -65 * marginMultiplier,
            maxY: -8 * marginMultiplier,
        },
        {
            src: animationItem07,
            maxX: 185 * marginMultiplier,
            maxY: -51 * marginMultiplier,
            scale: 1.33
        },
        {
            src: animationItem08,
            maxX: -173 * marginMultiplier,
            maxY: -56 * marginMultiplier,
            scale: 1.25
        },
        {
            src: animationItem09,
            maxX: -204 * marginMultiplier,
            maxY: -67 * marginMultiplier,
            opacity: myScroll1.scrollYProgress,
            scale: 1.25
        },
        {
            src: animationItem09,
            maxX: -65 * marginMultiplier,
            maxY: -8 * marginMultiplier,
            opacity: myScroll1.scrollYProgress,
        },
        {
            src: animationItem10,
            maxX: -65 * marginMultiplier,
            maxY: -8 * marginMultiplier,
        }
    ];
    const cpVariants = {
        show: {
            opacity: 1
        },
        hide: {
            opacity: 0,
            transition: {
                duration: .4,
            }
        }
    }
    return (
        <Container fluid className="overflow-hidden position-relative m-0 p-0">
            <div ref={cpRef} className="cp-scroll-reference"></div>
            <div className={'container mt-5 d-flex justify-content-center cpZIndexContainer'}>
                <div className="home-cellphone-animation-container pt-1" ref={containerRef}>
                    <motion.div
                        initial={'show'}
                        animate={(trigger) ? 'show' : 'hide'}
                        variants={cpVariants}
                        className="home-cellphone-image-container">
                        <Image className="main-cellphone-image"
                               src={mainCellphone}
                               alt="Image of a cellphone"
                               fluid
                        />
                        <div>
                            {images.map((image, index, array) => {
                                return (
                                    <CellPhoneAnimationItem scrollYProgress={myScroll1.scrollYProgressSpring}
                                                            key={index}
                                                            arraySize={array.length}
                                                            index={index}
                                                            src={image.src}
                                                            scale={image.scale}
                                                            opacity={image.opacity}
                                                            maxX={image.maxX}
                                                            maxY={image.maxY}></CellPhoneAnimationItem>
                                )
                            })}
                        </div>
                    </motion.div>
                </div>
            </div>
        </Container>
    );
};

export default CellphoneAnimation;