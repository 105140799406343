import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ThankYouApp from "./components/ThankYouApp";
import ErrorApp from "./components/ErrorApp";
const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorApp/>
    },
    {
        path: "/thanks",
        element: <ThankYouApp />,
    },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
