import {Col, Row, Image} from "react-bootstrap";
import aboutImage from "../../img/about/about-image.png"
import React, {forwardRef, useContext} from "react";
import {motion} from 'framer-motion'
import Carrousel from "../Carrousel/Carrousel";
import CarrouselMobile from "../Carrousel/CarrouselMobile";
import BreakpointsContext from "../Utils/BreakpointsContext";

const About = forwardRef(({trigger, pageSnapTrigger}, ref) => {
    const breakpoints = useContext(BreakpointsContext);
    const sectionVariants = {
        show: {
            opacity: 1,
            transition: {
                duration: .6,
                delay: .2
            }
        },
        hidden: {
            opacity: 0
        }
    }
    const snapVariants = {
        snap: {
            scrollSnapAlign: 'center',
            scrollSnapStop: 'always',
            transition: {
                duration: 0
            }
        },
        unsnap: {
            scrollSnapAlign: 'initial',
            scrollSnapStop: 'initial',
        }
    }
    return (
        <motion.section
            initial={'unsnap'}
            animate={(pageSnapTrigger) ? 'snap' : 'unsnap'}
            variants={snapVariants}
            id={'about'} className={'about-section'} ref={ref}>
            <motion.div className={'container py-3'} variants={sectionVariants} initial={'hidden'}
                        animate={(trigger) ? 'show' : 'hidden'}>
                <Row>
                    <Col lg={4} xs={12} className={'order-1 order-lg-2'}>
                        <Image
                            src={aboutImage}
                            className={'p-4'}
                            alt={'about section image'}
                            fluid
                        />
                    </Col>
                    <Col lg={8} xs={12} className={'order-2 order-lg-1 p-3'}>
                        <h4>Acerca De</h4>
                        <p>
                            En Bot2BMe, nos enorgullece contar con una sólida experiencia en la creación de software de
                            asistente virtual. Nos esforzamos por ofrecer servicios de alta calidad, siempre buscando
                            maneras de mejorar
                            y adaptarnos a las necesidades de nuestros clientes.<br/><br/>
                            Valoramos la simplicidad, la eficiencia y la satisfacción del usuario, manteniendo nuestro
                            compromiso
                            con los clientes en cada solución que brindamos.
                        </p>
                    </Col>
                </Row>
            </motion.div>
            {(breakpoints.isDesktop) &&
                <Carrousel/>
            }
            {(breakpoints.isMobile) &&
                <CarrouselMobile/>
            }
        </motion.section>
    )
});
export default About;