function ScrollOffsets(props) {
    const _offsetMap = {};

    function getCurrent() {
        return props.breakpoint ? _offsetMap[props.breakpoint] : null;
    }

    function set(breakpoint, value) {
        _offsetMap[breakpoint] = value;
    }

    return {
        getCurrent,
        set
    }

}

export default ScrollOffsets;