import Background from "../Background/Background";
import React from "react";
import {Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {motion} from "framer-motion";

const ErrorPage = () => {
    return (
        <section className={'error-section'}>
            <Background color="blue" trigger={true}/>
            <Container>
                <Row className={'d-flex align-content-center justify-content-center'}>
                    <h4>OOPS.. PÁGINA NO ENCONTRADA.</h4>
                    <p className={'mb-3'}>Parece que has seguido un link roto o ingresado una URL que no existe en este
                        sitio.</p>
                    <motion.a
                        type="button"
                        href={'/'}
                        initial={{transform: 'scaleX(1)'}}
                        whileHover={{
                            transform: 'scaleX(1.03)',
                            transition: {duration: .12}
                        }}
                        className="btn btn-tertiary main-button button-navbar error-button ps-5 pe-5 ps-lg-3 pe-lg-3 pt-lg-2 pb-lg-2 ms-lg-2">
                        VOLVER AL SITIO &#129122;
                    </motion.a>
                </Row>
            </Container>
        </section>
    );
}
export default ErrorPage