import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import headerLogoBgBlue from '../../img/header/logo-header-bg-blue.svg'
import headerLogoColor from '../../img/header/logo-header-color.svg'

function HeaderSimplified() {
    return (
        <Navbar expand="lg" className="header-navbar px-lg-5" fixed="top">
            <div className="ps-lg-0 pe-lg-0 container">
                <Navbar.Brand className="ms-0 ms-lg-auto pb-0 pt-0" href="/">
                    <img
                        src={headerLogoBgBlue}
                        width="196"
                        height="34"
                        className="d-inline-block align-top"
                        alt="Header Logo for Blue Background"
                    />
                </Navbar.Brand>
                <Navbar.Offcanvas
                    id={'basic-navbar-nav'}
                    aria-labelledby={'basic-navbar-nav-label'}
                    placement="end"
                    className="pb-lg-0 pb-3 ps-lg-0 pe-lg-0 ps-2 pe-2"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={'basic-navbar-nav-label'}>
                            <img
                                src={headerLogoColor}
                                width="193"
                                height="34"
                                className="d-inline-block align-top"
                                alt="Header Logo for Menu"
                            />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                </Navbar.Offcanvas>
            </div>
        </Navbar>

    );
}

export default HeaderSimplified;